import React from 'react';
import styles from './MaintenancePage.module.css'; 
import { GrHostMaintenance } from "react-icons/gr";
import { PacmanLoader } from 'react-spinners';

const MaintenancePage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <GrHostMaintenance className={styles.logo} />
        <h1 className={styles.heading}>Our Website is Currently Under Maintenance</h1>
        <p className={styles.subtext}>
          We are working hard to bring you a better experience. Stay tuned!
        </p>
        <div className={styles.loaderWrapper}>
          <PacmanLoader color="#BA9976" size={30} /> 
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
